<template>
  <div>
    <b-container class="base-container-x pt-2" style="min-height:400px;background-color:#fff">
      <b-row class="mb-5">
        <b-col cols="12">
          <StepFormRegister :currentStep="this.currentStep" :titleListSteps="this.titleListSteps" />
        </b-col>
        <b-col xl="12" lg="12" sm="12" md="12" class="text-center mt-1 mt-md-4">
          <h4 class="" v-if="teacher && !teacher.is_approved"> {{ $t('Status') }} : {{ $t('Waiting for approval') }}</h4>
          <h4 class="" v-if="teacher && teacher.is_approved"> {{ $t('Status') }} : {{ $t('Waiting for payment') }}</h4>
          <b-button v-if="teacher && teacher.is_approved && teacher.status == 1" pill class="btn-primary-dark mt-4 font-weight-bold px-5" @click="$router.push({ name: 'billing' })">{{ $t('Billing') }}</b-button>
          <b-button v-if="teacher && teacher.status == 2" pill class="btn-primary-dark mt-4 font-weight-bold px-5" @click="$router.push({ name: 'teacherProfile' })">{{ $t('View profile') }}</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

import StepFormRegister from '../../components/form/StepFormRegister'
import Api from '../../api/api'

export default {
  name: 'TeacherRegisterSuccess',
  title: ' | Teacher register success',
  components: {
    StepFormRegister
  },
  data () {
    return {
      teacher: null,
      currentStep: 4,
      titleListSteps: [this.$t('Start'), this.$t('Teacher info'), this.$t('Yoga info'), this.$t('Document'), this.$t('Payment'), this.$t('Finished')]
    }
  },
  computed: {
    teacherId () {
      return this.$store.getters.teacherId
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    const teacherId = localStorage.getItem('teacherId')
    if (teacherId) {
      this.getTeacher(teacherId)
    } else {
      this.$router.push({ name: 'teacherRegisterDocument' })
    }
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    getTeacher (id) {
      Api.teacherDetail({ id: id }).then(response => {
        if (response) {
          this.teacher = response.data
        }
      })
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
<style >

</style>
